<script setup lang="ts">
import { FormKitLazyProvider } from '@formkit/vue'
import { countryPrefixes } from '@design-system/data/countryPrefixes'
import { getNode } from '@formkit/core'
import type { LoginRegistrationFormProps } from './LoginRegistrationForm.props'
import type { FormKitNode } from '@formkit/core'

defineProps<LoginRegistrationFormProps>()

const { maskConfig } = useDateOfBirth()
const { dateOfBirthConfig, dateOfBirthLabel } = useDateOfBirthInputTextConfig()

const { ts } = useI18n()
const { REGISTRATION_FORM_PERSONAL_INFO_ID, isSignupPage, isOtpValid } =
  useRegistration()

const { emailExistOnAuth0 } = useLogin()

const node = ref(getNode(REGISTRATION_FORM_PERSONAL_INFO_ID))

const { privacyPolicy } = useRouteHelper()

const prefixes = countryPrefixes.map(country => ({
  label: `${country?.code} ${country?.dial_code}`,
  value: `${country?.code} ${country?.dial_code}`,
}))

// GENDER MAPPING
const genderChoose = [
  {
    label: ts('myAccount.registration.personalInfo.genderChoose.female'),
    value: 'female',
  },
  {
    label: ts('myAccount.registration.personalInfo.genderChoose.male'),
    value: 'male',
  },
  {
    label: ts('myAccount.registration.personalInfo.genderChoose.other'),
    value: 'unknown',
  },
]

// MARKETING MAPPING
const isMarketingConsentByEmail = [
  {
    label: ts(
      'myAccount.registration.contactPreferences.marketingPurposesOptions.yes'
    ),
    value: true,
  },
  {
    label: ts(
      'myAccount.registration.contactPreferences.marketingPurposesOptions.no'
    ),
    value: false,
  },
]

const isProfilingConsentByChannel = [
  {
    label: ts(
      'myAccount.registration.contactPreferences.marketingPurposesOptions.yes'
    ),
    value: true,
  },
  {
    label: ts(
      'myAccount.registration.contactPreferences.marketingPurposesOptions.no'
    ),
    value: false,
  },
]

const isPhoneNumberProvided = computed(() => {
  if (!node?.value?.context?.value.phone_number) return false
  const phoneNumber = node?.value.context?.value['phone_number'] as
    | string
    | undefined
  return phoneNumber !== undefined && phoneNumber !== ''
})

const isMarketingAuthorized = computed(() => {
  if (!node?.value?.context?.value.marketing_authorization) return false

  return node?.value.context?.value.marketing_authorization as
    | boolean
    | undefined
})

const disableInput = computed(() => {
  return !(isPhoneNumberProvided.value && isMarketingAuthorized.value)
})

// CHANNEL MAPPING
const channelsChoose = computed(() => [
  {
    label: ts('myAccount.registration.contactPreferences.channel.newsletter'),
    value: 'Email',
    attrs: { disabled: disableInput.value },
  },
  {
    label: ts('myAccount.registration.contactPreferences.channel.phone'),
    value: 'Phone',
    attrs: { disabled: disableInput.value },
  },
  {
    label: ts('myAccount.registration.contactPreferences.channel.smsOrMms'),
    value: 'SMS/MMS',
    attrs: { disabled: disableInput.value },
  },
])

const selectAll = () => {
  const nodeChannelPreferences: FormKitNode<string[]> | undefined = getNode(
    'channels_preferences'
  )
  if (!nodeChannelPreferences) return

  const valueNodeChannelPreferences: string[] =
    nodeChannelPreferences?.value ?? []

  if (valueNodeChannelPreferences.length === 3) {
    nodeChannelPreferences.input([])
  } else {
    nodeChannelPreferences.input(
      channelsChoose.value
        .filter(item => !item.attrs.disabled)
        .map(item => item.value)
    )
  }
}

watch(isMarketingAuthorized, newValue => {
  const nodeChannelPreferences = getNode('channels_preferences')
  if (newValue === false && nodeChannelPreferences) {
    if (!nodeChannelPreferences) return
    nodeChannelPreferences.input([])
  }
})

const isPhoneNumberValid = computed(() => {
  if (node.value?.context?.value.phone_number)
    return /^\d+$/.test(node.value?.context?.value.phone_number)
})
</script>

<template>
  <div class="gap-lg md:gap-xl flex flex-col">
    
<FormKitLazyProvider config-file="true">
<div
      class="gap-md flex flex-col"
      :class="[
        !isSignupPage
          ? 'p-md bg-primitives-off-white'
          : 'border-primitives-grey-100 border-t py-10',
      ]"
    >
      <h3 class="text-medium-5 uppercase">
        {{ $ts('myAccount.registration.personalInfo.personalInfoTitle') }}
      </h3>
      <!-- radio button -->
      <div ref="formPersonalInfoScroll">
        <FormKit
          name="gender"
          type="myRadio"
          autocomplete="sex"
          validation="required"
          :validation-messages="{
            required: ts('myAccount.registration.required'),
          }"
          :options="genderChoose"
          :classes="{
            label: 'text-medium-5',
            options: 'flex flex-wrap gap-x-xl xl:flex-nowrap gap-y-xs',
          }"
        />
      </div>
      <!-- INPUTS TAG -->
      <div class="gap-lg flex flex-col">
        <div class="gap-md flex">
          <div class="w-1/2">
            <InputsTextFields
              :label="$ts('myAccount.registration.personalInfo.firstName')"
              name="first_name"
              validation="required"
              autocomplete="given-name"
              :validation-messages="{
                required: ts('myAccount.registration.fieldEmpty'),
              }"
              show-remove-button
              @remove="() => node?.find('first_name', 'name')?.input('')"
            />
          </div>
          <div class="w-1/2">
            <InputsTextFields
              :label="$ts('myAccount.registration.personalInfo.lastName')"
              name="last_name"
              autocomplete="family-name"
              validation="required"
              :validation-messages="{
                required: ts('myAccount.registration.fieldEmpty'),
              }"
              show-remove-button
              @remove="() => node?.find('last_name', 'name')?.input('')"
            />
          </div>
        </div>
        <div class="gap-md flex items-end">
          <div class="w-2/5">
            <InputsSelect
              id="prefix"
              :label="$ts('myAccount.registration.personalInfo.prefix')"
              role="combobox"
              autocomplete="tel-country-code"
              name="prefix"
              :options="prefixes"
            />
          </div>
          <div class="w-3/5">
            <InputsTextFields
              :label="$ts('myAccount.registration.personalInfo.phoneNumber')"
              name="phone_number"
              type="mask"
              autocomplete="tel-locale"
              mask="{#|repeat}"
              show-mask="false"
              show-remove-button
              @remove="() => node?.find('phone_number', 'name')?.input('')"
            />
          </div>
        </div>
        <div>
          <InputsTextFields
            type="mask"
            :mask="maskConfig"
            :label="dateOfBirthLabel"
            name="date_of_birth"
            autocomplete="bday"
            validation="required"
            :validation-messages="{
              required: ts('myAccount.registration.fieldEmpty'),
            }"
            :tokens="dateOfBirthConfig"
          />
        </div>
        <InputsPassword
          id="password"
          name="password"
          :label="$ts('myAccount.registration.personalInfo.password')"
          validation="required|length:8,15|matches:/^(?=.*\d)(?!.*\s)/"
          :validation-messages="{
            matches: $ts('myAccount.registration.personalInfo.passwordInfo'),
          }"
          aria-describedby="password-description"
          :show-validation-bar="true"
          :autocomplete="false"
          :classes="{
            outer: 'mb-4 scroll-mt-10',
          }"
          @remove="() => node?.find('password', 'name')?.input('')"
        />
        <p
          id="password-description"
          class="text-light-6 text-primitives-grey-200"
        >
          {{
            $ts('myAccount.registration.personalInfo.passwordInfo') +
            $ts('myAccount.registration.personalInfo.passwordTip')
          }}
        </p>
      </div>
    </div>

    <div
      v-if="isOtpValid || !emailExistOnAuth0"
      class="gap-xl flex flex-col"
      :class="[
        !isSignupPage
          ? 'p-md bg-primitives-off-white'
          : 'border-primitives-grey-100 border-t py-10',
      ]"
    >
      <div class="gap-md flex flex-col">
        <h3 class="text-medium-5 uppercase">
          {{
            $ts(
              'myAccount.registration.contactPreferences.contactPreferencesTitle'
            )
          }}
        </h3>
        <div class="gap-sm flex flex-col">
          <span v-if="!isPhoneNumberValid" class="text-light-6">
            {{
              $ts(
                'myAccount.registration.contactPreferences.contactPreferencesTitle'
              )
            }}
          </span>
          <div class="gap-sm flex flex-col">
            <FormKit
              name="marketing_authorization"
              :classes="{
                options: 'flex gap-xl',
                label: 'text-medium-5',
                legend: 'text-light-6 font-light mb-6',
              }"
              type="myRadio"
              :label="
                isPhoneNumberValid
                  ? $ts(
                      'myAccount.registration.contactPreferences.marketingPurposesByChannel'
                    )
                  : $ts(
                      'myAccount.registration.contactPreferences.marketingPurposesByEmail'
                    )
              "
              :options="isMarketingConsentByEmail"
              validation="required"
              :validation-messages="{ required: $ts('fieldRequired') }"
            />
          </div>
        </div>
        <div
          v-if="isPhoneNumberValid"
          class="gap-md flex flex-col"
          :class="{ 'pointer-events-none': disableInput }"
        >
          <span class="text-light-6" :class="{ 'opacity-20': disableInput }">
            {{ $ts('myAccount.registration.contactPreferences.selectChannel') }}
          </span>
          <div class="flex flex-col">
            <div>
              <AtomsCta
                anatomy="link"
                :disabled="disableInput"
                :class="{ 'opacity-20': disableInput }"
                @click.stop.prevent="selectAll"
              >
                <!-- NOTE! actually there are only 3 fields, could be change in the future -->
                <template
                  v-if="
                    node?.context?.value.channels_preferences &&
                    node?.context?.value.channels_preferences.length === 3
                  "
                  #label
                >
                  {{
                    $ts('myAccount.registration.contactPreferences.deSelectAll')
                  }}
                </template>
                <!-- NOTE! actually there are only 3 fields, could be change in the future -->
                <template v-else #label>
                  {{
                    $ts('myAccount.registration.contactPreferences.selectAll')
                  }}
                </template>
              </AtomsCta>

              <FormKit
                id="channels_preferences"
                type="myCheckbox"
                name="channels_preferences"
                :options="channelsChoose"
                :validation="
                  node?.context?.value?.marketing_authorization
                    ? 'required'
                    : ''
                "
                :validation-messages="{ required: $ts('fieldRequired') }"
                :classes="{
                  option: ' bg-primitives-white p-sm text-medium-5',
                  options: 'flex flex-col gap-sm mt-4',
                }"
              />
            </div>
          </div>
        </div>

        <div class="gap-md flex flex-col">
          <div>
            <div class="border-primitives-grey-100 pb-md border-b">
              <FormKit
                name="profiling_consent"
                :classes="{
                  options: 'flex gap-xl',
                  label: 'text-medium-5',
                  legend: 'text-light-6 font-light mb-6',
                }"
                type="myRadio"
                :label="
                  $ts(
                    'myAccount.registration.contactPreferences.profilingConsentTextLabel'
                  )
                "
                :options="isProfilingConsentByChannel"
                validation="required"
                :validation-messages="{ required: $ts('fieldRequired') }"
              />
            </div>
            <div
              class="markdown-text text-link-6 text-primitives-grey-200 pt-md"
            >
              <UtilsMarkdown
                :content="
                  $ts(
                    'myAccount.registration.contactPreferences.privacyPolicy',
                    {
                      policy: ts(
                        'myAccount.registration.contactPreferences.privacyPolicyBold',
                        { link: privacyPolicy }
                      ),
                    }
                  )
                "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <slot name="cta" />
</FormKitLazyProvider>

  </div>
</template>

<style lang="scss">
.markdown-text {
  span {
    @apply text-primitives-black underline;
  }
}
</style>

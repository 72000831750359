<script setup lang="ts">
import type { LoginSocialListProps } from './LoginSocialList.props'

import IconsFacebook from '~icons/icons/Facebook.svg'
import IconsApple from '~icons/icons/Apple.svg'
import IconsGoogle from '~icons/icons/Google.svg'

const { dispatchAccountOAuthEvent } = useGAAccountEvents()
const { socialLogin } = useAuth0()

const props = withDefaults(defineProps<LoginSocialListProps>(), {
  socials: () => ['google-oauth2', 'facebook', 'apple'],
  width: 'full',
})

const list = [
  {
    key: 'facebook',
    logo: IconsFacebook,
    color: '#1877F2',
    name: 'Facebook',
  },
  /*
  {
    key: 'apple',
    logo: IconsApple,
    name: 'Apple',
  },
  */
  {
    key: 'google-oauth2',
    logo: IconsGoogle,
    name: 'Google',
  },
]

const clickSocialHandler = ({ key, name }: { key: string; name: string }) => {
  dispatchAccountOAuthEvent(name)
  socialLogin(key)
}

const socialList = computed(() => {
  return list.filter(item => props.socials.includes(item.key))
})
</script>
<template>
  <div>
    <div v-if="$slots.title" class="text-medium-5 mb-xl">
      <slot name="title"></slot>
    </div>
    <div
      class="gap-sm flex w-full flex-col"
      :class="[
        { 'sm:max-w-[400px]': width === 'medium' },
        { 'max-w-[258px]': width === 'small' },
      ]"
    >
      <button
        v-for="social in socialList"
        :key="`${social.key}`"
        type="button"
        class="py-xs border-primitives-black pl-xs pr-xxl flex items-center border"
        @click="clickSocialHandler({ key: social.key, name: social.name })"
      >
        <component
          :is="social.logo"
          aria-hidden="true"
          class="h-8 w-8 shrink-0"
          :style="{ color: social.color }"
        />
        <span class="text-button-2 flex-1 text-center">
          {{ social.name }}
        </span>
      </button>
    </div>
  </div>
</template>
